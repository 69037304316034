// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-idea-js": () => import("./../src/pages/idea.js" /* webpackChunkName: "component---src-pages-idea-js" */),
  "component---src-pages-impressum-js": () => import("./../src/pages/impressum.js" /* webpackChunkName: "component---src-pages-impressum-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-numbers-js": () => import("./../src/pages/numbers.js" /* webpackChunkName: "component---src-pages-numbers-js" */),
  "component---src-pages-privacy-js": () => import("./../src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */),
  "component---src-templates-product-js": () => import("./../src/templates/product.js" /* webpackChunkName: "component---src-templates-product-js" */),
  "component---src-templates-recipe-js": () => import("./../src/templates/recipe.js" /* webpackChunkName: "component---src-templates-recipe-js" */),
  "component---src-templates-video-js": () => import("./../src/templates/video.js" /* webpackChunkName: "component---src-templates-video-js" */)
}

